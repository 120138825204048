import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ImageView from "../components/imageView"
import useWindowSize from "../hooks/useWindowSize"

const LocationPage = () => {
  const windowSize = useWindowSize()
  const renderedImageHeight = windowSize.width * (3724 / 4961)
  if (windowSize.height == undefined) {
    return <></>
  }
  const initialScale =
    renderedImageHeight < windowSize.height
      ? windowSize.height / renderedImageHeight
      : 1
  return (
    <Layout>
      <Seo title="Location" />
      <ImageView initialScale={initialScale}>
        <StaticImage
          objectFit="contain"
          className="object-contain md:object-contain min-h-screen h-full"
          src="../images/location.jpg"
          alt="Floor plan"
          quality={100}
          breakpoints={[4961]}
          loading="eager"
          placeholder="blurred"
          formats={["auto", "webp", "avif"]}
        />
      </ImageView>
    </Layout>
  )
}

export default LocationPage
